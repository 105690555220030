<template>
  <!-- 
    Сторінка "Штрафи"
      - дозволяє переглянути список штрафів ( з пошуком по імені/номеру телефона), та з фільтрацією по статусу
      - відкрити картку штрафу
      - викликати створення штрафу
      - та інше :)
  -->

  <!-- Компонент хедеру розділу -->
  <PageHeader :title="title"  />

  <!-- кнопка відображення інфо-блоків -->
  <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
      <div class="hstack gap-2">
          <button 
            v-if="this.checks['001'] == true" 
            @click="infoblockShow = !infoblockShow" 
            type="button" 
            :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"
          >
            <i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i>
          </button>
      </div>
  </div>

  <!-- 
      Інформаційні блоки з підсумком по -
      - усього штрафів та на яку суму
      - скільки штрафів підтвердженно та на яку суму
      - скільки штрафів очікують підтвердження та на яку суму
      - скільки штрафів анульовано та на яку суму
  -->
  <infoblocks v-if="infoblockShow" :obj="this.information" style="margin-top:15px;" />

  <!-- 
    Компонент таблиці
    - :dataParams - параметри до кнопки фільтру
    - :objParams - параметри для фільтрації таблиці 
    - :columns - конфігурація стовпців таблиці
    - :rows - масив з даними рядків таблиці
    - :pages - об'єкт із даними про пагінацію таблиці
    - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
    - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
    - - йде перевірка на права доступу 2000 (режим бога) та 707 (створення штрафів) та перевірку хто переглядає картку співробітника
    - @create - подія, яка спрацьовує при створенні нового штрафу
    - @open - подія, яка спрацьовує при відкритті картки штрафу
    - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
    - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
  -->
  <tablecustom 
      :dataParams="dataParams"
      :objParams="objParams"
      :columns="columns" 
      :rows="rows"
      :pages="objPages"
      :showpag="true"
      :createdform="this.perms[707] || this.perms[2000]"
      @create="modalCreatedBox = true"
      @changelimit="changelimit"
      @changeFilter="changeFilter" 
      @getdata="getdata"
      @open="open"
  />

  <!-- 
    Виклик відкриття картки штрафу
    - для відкриття застосовується перевірка значення modalDialog
    - @close - подія, яка спрацьовує при закритті картки
    - :obj - параметр-об'єкт з даними по штрафу
    - :ifPrevopen - параметр, який передає статус відображення стрілочки назад
    - :ifNextopen - параметр, який передає статус відображення стрілочки вперед
    - @prevOpen - подія, яка спрацьовує коли натискаєш на стрілочку назад
    - @nextOpen - подія, яка спрацьовує коли натискаєш на стрілочку вперед
  -->
  <viewbox 
      v-if="modalDialog == true"
      @close="closeModal" 
      :obj="this.objCard" 
      :ifPrevopen="ifPrevopen" 
      :ifNextopen="ifNextopen" 
      @prevOpen="prevOpen" 
      @nextOpen="nextOpen"
      @edit="edit"
  />

  <!-- 
    Виклик вікна створення картки штрафу
    - для відкриття застосовується перевірка значення modalCreatedBox
    - @close - подія, яка спрацьовує при закритті картки
  -->
  <createdBox 
    v-if="modalCreatedBox == true"
    @close="closeCreate"
    :perm="this.permToEdit" 
    :obj="this.form"
  />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import viewbox from './view' // картка штрафу
import createdBox from './create' // картка створення штрафу
import infoblocks from '@/components/info/blocks' // інфо-блоки
import { Fines } from '@/API.js' // клас для взаємодії з API 
import { storeS } from '@/store'; // глобальне сховище даних
import { mutatetypeFine, mutateStatusFine } from '@/usabilityScripts/globalMutate.js'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Fines();

export default {
  components: {
    PageHeader,
    tablecustom,
    infoblocks,
    viewbox,
    createdBox
  },
  data(){
    return{
        title: this.$t('Fines'), // Заголовок
        permToEdit: false, // прапорець для редагування
        infoblockShow: false, // прапорець відображення інфо-блоків
        modalDialog: false, // прапорець відображення вікна штрафу
        modalCreatedBox: false, // прапорець відображення вікна створення штрафу
        objCard: {}, // Об'єкт з даними штрафу для відображення в модальному вікні
        form: '',
        ifNextopen: "", // Прапорець статусу відображення кнопки "<"
        ifPrevopen: "", // Прапорець статусу відображення кнопки ">"
        objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
        dataParams: { // Параметри для фільтрації таблиці
          status: true, // true - показати кнопку фільтр, false - сховати
          page: "fines"
        },
        objParams:{ // Параметри для фільтрації даних
            page: '1',
            pagelimit: '10',
            // search: '',
            workerId: '',
            finedWorkerId: '',
            status: '',
            pr1: '',
            pr2: ''
        },
        columns: [
            // Конфігурація колонок таблиці з інформацією про кожну колонку
            // Кожен елемент містить:
            // * name - назву колонки для локалізованого відображення,
            // * text - Ключове слово для отримання значення з даних
            // * align - Вирівнювання
            // * status - Статус відображення колонки
            // * mutate - Функція для мутації значення колонки (переклад)
            // * mutateClass - Функція для мутації класу колонки (стилі)
            {
                name: 'ID',
                text: 'fineId',
                align: 'left',
                status: true,
            },
            {
                name: this.$t("typeFine"),
                text: "type",
                align: "left",
                status: true,
                mutate: (item) => this.mutatetype(item),
            },
            {
                name: this.$t('Employee'),
                text: 'workerName',
                align: 'left',
                status: true,
            },
            {
                name: this.$t("Status"),
                text: "fineStatus",
                align: "left",
                status: true,
                mutate: (item) => this.mutatestatus(item).name,
                mutateClass: (item) => this.mutatestatus(item).color
            },
            {
                name: this.$t("Sum"),
                text: "fineSum",
                align: "left",
                status: true,
            },
            {
                name: this.$t("added"),
                text: "createWorkerName",
                align: "left",
                status: true,
            },
            {
                name: this.$t("createDate"),
                text: "createDatetime",
                align: "left",
                status: true,
            }
        ],
        rows: [], // Дані для відображення у таблиці
        information: [
          // Дані компонента, які відображають інформацію про кількість та суми штрафів в різних статусах.
          // Кожен елемент масиву містить:
          // * title - назву інформаційного блоку,
          // * value - кількість штрафів у відповідному статусі,
          // * icon - клас іконки,
          // * color - колір інформаційного блоку,
          // * sum - загальну суму штрафів у відповідному статусі.
          {
              title: this.$t('Total'),
              value: "0",
              icon: "las la-file-invoice",
              color: "info",
              sum: "0",
          },
          {
              title: this.$t('AwaitingСonfirmation'),
              value: "0",
              icon: "las la-file-invoice",
              color: "success",
              sum: "0"
          },
          {
              title: this.$t('Confirmed'),
              value: "0",
              icon: "las la-file-invoice",
              color: "warning",
              sum: "0"
          },
          {
              title: this.$t('annul'),
              value: "0",
              icon: "las la-file-invoice",
              color: "danger",
              sum: "0"
          }
        ],
    }
  },
  created(){
    this.getdata();
  },
  methods:{
    getdata(page){
        /**
          Отримує дані про штрафи з API та встановлює їх у властивості компонента.

          @method getdata
          @param {Number} page - Номер сторінки для отримання даних.
        */

        this.objParams.page = page != undefined ? page : '1';
        apiServe.getAllFines(this.objParams).then(result => {
            if(result.status == 'done') {
              this.rows = result.data.items;
              this.objPages = result.data;

              this.information[0].value = result.data.totalCount;
              this.information[1].value = result.data.countWaitСonfirm;
              this.information[2].value = result.data.countConfirm;
              this.information[3].value = result.data.countCancel;
              this.information[0].sum = result.data.totalSum;
              this.information[1].sum = result.data.sumWaitСonfirm;
              this.information[2].sum = result.data.sumConfirm;
              this.information[3].sum = result.data.sumCancel;
            } else {
              this.$toast.error(this.$t('error') + ` #380`);
            }
        })
    },
    closeModal(){
      /*
        Функція для закриття модального вікна.
        Встановлює прапорці modalDialog в false, та викликає функцію getdata для оновлення даних таблиці.
      */

      this.modalDialog = false;
      this.getdata(this.objParams.page);
    },
    open: function(e){
      /**
        Функція для відкриття модального вікна з інформацією про штраф за його ідентифікатором.
        * @param {Object} e - об'єкт картки штрафу

        Викликає API для отримання інформації про штраф.
        Результат присвоюється властивості fine, та встановлюється прапорці modalDialog в true для відображення модального вікна.
      */

      var id = e.fineId ? e.fineId : e;
      // this.ifPrevopen = id == this.rows[0].fineId ? false : true;
      // this.ifNextopen = id == this.rows[this.rows.length-1].fineId ? false : true;
      
      
      apiServe.getFine(id).then(result => {
          if(result.status == 'done'){
              this.objCard = result.data;
              this.modalDialog = true;
          } else {
            this.$toast.error(this.$t('error') + " " + result.error[0]);

            var errorText = 'Помилка отримання картки штрафу'; 
            var statusRequest = result.status;
            var api = 'getFine';
            var fileRequest = 'src/views/statistics/fines/index.vue';
            var params = id;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
      }) 
    },
    // prevOpen(e){
    //   var current_id = e;
    //   var prev_id = ""; 

    //   for(var value in this.rows){
    //     if(this.rows[value].fineId == current_id){
    //       prev_id = this.rows[Number(value)-Number('1')].fineId;
    //     }
    //   }
    //   this.open(prev_id);
    // },
    // nextOpen(e){
    //   var current_id = e;
    //   var next_id = "";

    //   for(var value in this.rows){
    //     if(this.rows[value].fineId == current_id){
    //       next_id = this.rows[Number(value)+Number('1')].fineId;
    //     }
    //   }
    //   this.open(next_id);
    // },
    edit(e) {
      /**
        * Запускає режим редагування для вказаного елемента та відкриває модальне вікно редагування.
        * @param {Object} e - Об'єкт елемента, який буде редагуватися.
      */

      this.permToEdit = true;
      this.form = e;
      this.modalCreatedBox = true;
    },
    closeCreate() {
      /**
        * Закриває модальне вікно створення або редагування та скидає флаг редагування.
        * Якщо відкрите модальне вікно перегляду, оновлює дані після закриття.
      */

      this.permToEdit = false;
      this.modalCreatedBox = false;
      if(this.modalDialog == true){
        this.modalDialog = false;
        this.getdata();
      }
    },
    changeFilter(name, value){
      /*
        Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
      */

      if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else {
          this.objParams[name] = value;
        }
        this.getdata();
      }
    },
    changelimit: function(limit){
      /*
        Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
      */
      
      this.objParams.pagelimit = limit;
      this.getdata();
    },
    mutatetype(e){
      /*
        Функція для застосування перекладу до типу штрафу
      */

      return mutatetypeFine(e)
    },
    mutatestatus(e){
      /*
        Функція для застосування перекладу до статусу штрафу
      */
      return mutateStatusFine(e)
    },
  },
  mounted(){
    // Cлухання події saveFines для оновлення даних після збереження штрафів
    this.eventBus.off('saveFines');
    this.eventBus.on('saveFines', (status) => {
      if(status == true) {
        this.getdata();
      }
    })
  },
  computed: {
    user() {
      /*
        Повертає дані за користувача з глобального стору.
      */
      return storeS.userbase
    },
    perms() {
      /*
        Повертає дані прав доступу користувача з глобального стору.
      */
      return storeS.perms
    },
    checks() {
      /*
        Повертає дані з налаштувань юзера з глобального стору.
      */
      return storeS.checks
    }
  }
}
</script>